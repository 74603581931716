/* Coverage:
 Statements: 100%
 Branches: 100%
 Functions: 100%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { useContext } from 'react'
import type { ServerContextType } from '~/contexts/serverContext'
import { ServerContext } from '~/contexts/serverContext'
export type UseServerContextType = ServerContextType
export const useServerContext = (): UseServerContextType => {
  const {
    genreTreeById,
    genreTreeOrdered,
    programsById,
    allTags,
    tracksById,
    drillTypesById,
    isTest,
    supabaseUrl,
    supabaseAnonKey,
  } = useContext(ServerContext)
  return {
    genreTreeById,
    genreTreeOrdered,
    programsById,
    allTags,
    tracksById,
    drillTypesById,
    isTest,
    supabaseUrl,
    supabaseAnonKey,
  }
}
